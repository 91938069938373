var Flickity = require('flickity');
require('flickity-imagesloaded');

var sliders = document.querySelectorAll('.image-slider');

for (i = 0; i < sliders.length; i += 1) {
  var slider = new Flickity(sliders[i], {
    cellAlign: 'left',
    contain: true,
    imagesLoaded: true,
    arrowShape: {
      x0: 25,
      x1: 75, y1: 50,
      x2: 75, y2: 40,
      x3: 35
    }
  });
}
