var toggle = function(e) {
  this.parentNode.parentNode.classList.toggle("accordion--active");
  var ariaToggle = this.getAttribute("aria-expanded");

  if (ariaToggle === "true") {
    this.setAttribute("aria-expanded", "false");
    this.parentNode.nextElementSibling.setAttribute("aria-hidden", "true");
  } else {
    this.setAttribute("aria-expanded", "true");
    this.parentNode.nextElementSibling.setAttribute("aria-hidden", "false");
  }
  e.preventDefault();
};

var targets = document.querySelectorAll(".accordion__toggle");

for (var i = 0; i < targets.length; i += 1) {
  targets[i].setAttribute("aria-controls", "content-" + (i + 1));
  targets[i].setAttribute("id", "accordion-control-" + (i + 1));
  targets[i].parentNode.nextElementSibling.setAttribute(
    "id",
    "content-" + (i + 1)
  );
  targets[i].addEventListener("click", toggle);
}

var triggers = document.querySelectorAll(".accordion__trigger");

for (var i = 0; i < triggers.length; i +=1) {
  triggers[i].addEventListener("click", function(e) {
    window.onhashchange = function () {
      var hash = window.location.hash;
      console.log('window hash: ' + hash)
      var accordion = document.querySelector(hash);

      console.dir(accordion)
      if (accordion) {
        var toggleButton = accordion.querySelector(".accordion__toggle");

        toggleButton.click();
      }
    }
  });
}

var allExpands = document.querySelectorAll(".accordion__expand");

for (var i = 0; i < allExpands.length; i += 1) {
  allExpands[i].setAttribute("aria-label", "accordion control group" + (i + 1));
}

// Toggle All

var toggleAll = function(e) {
  var accordionExpand = e.target;
  var accordionGroup = e.target.parentNode;
  var targetAccordions = accordionGroup.querySelectorAll(".accordion__toggle");

  if (accordionGroup.classList.contains("expanded")) {
    accordionGroup.classList.remove("expanded");
    accordionExpand.textContent = "Expand All";
    accordionExpand.setAttribute("aria-expanded", "false");
    for (var i = 0; i < targetAccordions.length; i += 1) {
      targetAccordions[i].parentNode.parentNode.classList.remove(
        "accordion--active"
      );
      targetAccordions[i].setAttribute("aria-expanded", "false");
      targetAccordions[i].parentNode.nextElementSibling.setAttribute(
        "aria-hidden",
        "true"
      );
    }
  } else {
    accordionGroup.classList.add("expanded");
    accordionExpand.textContent = "Collapse All";
    accordionExpand.setAttribute("aria-expanded", "true");
    for (var i = 0; i < targetAccordions.length; i += 1) {
      targetAccordions[i].parentNode.parentNode.classList.add(
        "accordion--active"
      );
      targetAccordions[i].setAttribute("aria-expanded", "true");
      targetAccordions[i].parentNode.nextElementSibling.setAttribute(
        "aria-hidden",
        "false"
      );
    }
  }

  e.preventDefault();
};

var targetsAll = document.querySelectorAll(".accordion__expand");

for (var i = 0; i < targetsAll.length; i += 1) {
  targetsAll[i].addEventListener("click", toggleAll);
}
