var Flickity = require('flickity');
require('flickity-imagesloaded');

var scrolls = document.querySelectorAll('.grid__container--scroll');

for (var i = 0;  i < scrolls.length; i += 1) {
  var scroll = new Flickity(scrolls[i], {
    imagesLoaded: true,
    cellAlign: 'left',
    freeScroll: true,
    contain: true,
    prevNextButtons: false,
    pageDots: false,
    watchCSS: true
  });
  var scrolled = function() {
    this.classList.add('grid__container--scroll--scrolled');
  };
  scrolls[i].addEventListener('touchstart', scrolled);
  scrolls[i].addEventListener('mousedown', scrolled);
}
