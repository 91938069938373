var prevent = true;
var enable;
var close;

var click = function(e) {
  if (prevent || !this.parentNode.classList.contains('nav__item--active')) {
    over.call(this.parentNode);
    e.preventDefault();
  }
};

var targets = document.querySelectorAll('.nav__link--arrow');

for (var i = 0; i < targets.length; i += 1) {
  targets[i].addEventListener('click', click);
}

//

var items = document.querySelectorAll('.nav__item');
var subMenu = document.querySelectorAll('.nav__item .mega');
var closeButtons = document.querySelectorAll('.mega__close--icon');

var reset = function() {
  prevent = true;
  clearTimeout(enable);
  clearTimeout(close);
  for (var i = 0; i < items.length; i += 1) {
    items[i].classList.remove('nav__item--active');
    if (items[i].children[1] != null) {
      items[i].children[1].setAttribute('aria-expanded', false);  
    }
    
  }

  for (var i = 0; i < subMenu.length; i += 1) {
    subMenu[i].setAttribute('aria-hidden', true);
  }
  document.body.removeEventListener('click', outClick);
  document.removeEventListener('scroll', reset);
};

var over = function(e) {
  reset();
  this.classList.add('nav__item--active');
  this.children[1].setAttribute('aria-expanded', true);
  if (this.children[2] != null) {
    this.children[2].setAttribute('aria-hidden', false);
  }

  enable = setTimeout(function() {
    prevent = false;
  }, 100);
  document.body.addEventListener('click', outClick);
  document.addEventListener('scroll', reset);
};

var out = function(e) {
  close = setTimeout(reset, 300);
};

var outClick = function(e) {
  var el = document.querySelector('.nav__item--active');
  if (!el.contains(e.target)) {
    out();
  }
};

for (var i = 0; i < items.length; i += 1) {
  items[i].addEventListener('mouseover', over);
  items[i].addEventListener('mouseout', out);
}

for (var i = 0; i < closeButtons.length; i += 1) {
  closeButtons[i].addEventListener('click', out);
}

//

var hybrid = document.querySelector('.nav--hybrid');

var placehold = function() {
  var placeholder = document.querySelector('.nav-placeholder');
  var list = document.querySelector('.nav__list').getBoundingClientRect().width;
  placeholder.style.width = list + 'px';
};

var check = function() {
  var nav = document.querySelector('.nav-container');
  if (getComputedStyle(nav, null).display === 'block') {
    placehold();
    var body = document.body;
    var position = document.querySelector('.header-container').nextElementSibling.getBoundingClientRect().top;
    if (position <= 0) {
      body.classList.add('nav-fixed');
    } else {
      body.classList.remove('nav-fixed');
    }
  }
  requestAnimationFrame(check);
};

if (hybrid) {
  check();
}

//

var toggle = function(e) {
  document.body.classList.toggle('nav-search-active');
  if (document.body.classList.contains('nav-search-active')) {
    document.querySelector('.search--nav .search__input').focus();
    this.setAttribute('aria-expanded', true);
  }
  else {
    this.setAttribute('aria-expanded', false);
  }
  e.preventDefault();
};

var target = document.querySelector('.nav__search-toggle');

if (target) {
  target.addEventListener('click', toggle);
}


